import { getHeaders } from '../../headers';

export const createGoal = async (goal, baseUrl) => {
  const response = await fetch(`${baseUrl}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ goal }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};

export const deleteGoal = async (id, baseUrl) => {
  const response = await fetch(`${baseUrl}/${id}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};

export const updateGoal = async (goal, baseUrl) => {
  const response = await fetch(`${baseUrl}/${goal.id}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify({ goal }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};

export const fetchObservableGoals = async (observableId, baseUrl) => {
  const response = await fetch(`${baseUrl}/observable_goals/${observableId}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};
