import React, { useState } from 'react';
import { Grid, Button, FormControl, TextField, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';

export default function ActionStepsForm({ goal, leads, onSave, formProps, errors }) {
  const { description, setDescription, dueDate, setDueDate, leadId, setLeadId } = formProps;
  const menuItemStyle = {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const handleSave = (e) => {
    e.preventDefault();
    const payload = {
      description: description,
      due_date: dueDate,
      user_id: leadId,
      goal_id: goal.id,
    };
    onSave(payload);
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth size="small">
            <TextField
              label="Description"
              multiline
              rows={2}
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              error={errors.description?.length > 0}
              helperText={errors.description?.join(', ')}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth size="small">
            <DatePicker
              label="Due date"
              value={null}
              minDate={dayjs().add(1, 'day')}
              onChange={(date) => {
                const value = date ? date.format('YYYY-MM-DD') : null;
                setDueDate(value);
              }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  required: true,
                  InputLabelProps: { shrink: true },
                  size: 'small',
                  error: errors.due_date?.length > 0,
                  helperText: errors.due_date?.join(', '),
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth required size="small">
            <InputLabel id="lead-select-label">Lead</InputLabel>
            <Select
              labelId="lead-select-label"
              id="lead-select"
              value={leadId}
              label="Lead"
              onChange={(e) => setLeadId(e.target.value)}
              error={errors.user?.length > 0}
            >
              {leads
                ?.sort((a, b) => a.first_name.localeCompare(b.first_name))
                .map((user, index) => (
                  <MenuItem key={index} value={user.id}>
                    <div style={menuItemStyle}>{user.full_name}</div>
                  </MenuItem>
                ))}
            </Select>
            {errors.user?.length > 0 && <FormHelperText sx={{ color: '#d32f2f' }}>Lead must exist.</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
}
