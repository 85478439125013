import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import railsFetch from '../../helpers/rails_fetch';
import { useFormStatus } from '../../helpers/form_hooks';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFiles } from '@fortawesome/pro-regular-svg-icons';
import ResourcesList from './resources_list';
import VideoDialog from './video_dialog';

export default (props) => {
  const [showVideoResource, setShowVideoResource] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { processWithErrorFallback } = useFormStatus();

  const handleClick = (event) => {
    if (props.resources.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (id) => {
    const confirmDelete = confirm('Are you sure you want to delete this resource?');
    if (confirmDelete) {
      const fnPromise = railsFetch(`/attachments/${id}`, {
        method: 'DELETE',
      }).then(() => {
        props.handleUpdate([...props.resources.filter((r) => r.id !== id)]);
      });
      processWithErrorFallback(fnPromise, { withAlert: true });
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleShowVideo = (url) => {
    setShowVideoResource(url);
  };

  const actionStyle = {
    color: '#707070',
    textDecoration: 'none',
  };

  if (props.resources.length > 0) {
    return (
      <>
        <a onClick={handleClick} className="d-inline-block" style={actionStyle}>
          <Badge
            badgeContent={props.resources.length}
            color="primary"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faFiles}
              sx={{
                color: '#707070 !important',
                verticalAlign: 'middle',
              }}
            />
          </Badge>
        </a>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box sx={{ width: 300, p: 2 }}>
            <ResourcesList
              resources={props.resources}
              canEdit={!props.hideDelete}
              handleDelete={handleDelete}
              recordId={props.recordId}
              linkStyle={{ minWidth: '50px', maxWidth: '84%' }}
              onShowVideo={handleShowVideo}
            />
          </Box>
        </Popover>
        {showVideoResource && <VideoDialog resource={showVideoResource} isOpen={true} onClose={() => setShowVideoResource(null)} />}
      </>
    );
  } else {
    return (
      <FontAwesomeIcon
        icon={faFiles}
        style={{
          verticalAlign: 'middle',
          opacity: '0.5',
          color: '#707070',
        }}
      />
    );
  }
};
