import React, { useState } from 'react';

const TextTruncator = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleTextClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className="truncate-container"
      onClick={handleTextClick}
      style={{
        cursor: 'pointer',
        whiteSpace: isExpanded ? 'normal' : 'nowrap',
        overflow: isExpanded ? 'visible' : 'hidden',
        textOverflow: isExpanded ? 'clip' : 'ellipsis',
        position: 'relative',
        maxWidth: '100%',
      }}
      title={isExpanded ? 'Show less' : 'Show more'}
    >
      {text}
    </div>
  );
};

export default TextTruncator;
