import { getHeaders } from '../../headers';

export const createActionStep = async (action_step, baseUrl) => {
  const response = await fetch(`${baseUrl}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ action_step }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};

export const deleteActionStep = async (id, baseUrl) => {
  const response = await fetch(`${baseUrl}/${id}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};

export const updateActionStep = async (action_step, baseUrl) => {
  const response = await fetch(`${baseUrl}/${action_step.id}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify({ action_step }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};
