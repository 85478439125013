import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { faLink } from '@fortawesome/pro-solid-svg-icons';

/**
 * ResourcesList component
 * @param {Array} resources
 * @param {Boolean} canEdit
 * @param {Function} handleDelete
 * @param {Number} recordId - The attachment.record_id to compare with the record_id of the resource
 * @param onShowVideo
 * @returns {JSX.Element}
 * @constructor
 */
const ResourcesList = ({ resources, canEdit, handleDelete, recordId = null, onShowVideo = null }) => {
  const isDelegatedRecord = (resource) => {
    if (recordId === null) return false;
    return resource?.record_id !== recordId;
  };

  const isVideo = (resource) => {
    if (!resource?.filename) {
      return false;
    }
    const filename = resource.filename.toLowerCase();
    const extension = filename.split('.').pop();
    return ['mp4', 'avi', 'flv', 'wmv', 'mov'].includes(extension);
  };

  const canShowVideo = (resource) => {
    return isVideo(resource) && typeof onShowVideo === 'function';
  };

  const handleShowVideo = (event, resource) => {
    event.preventDefault();
    onShowVideo(resource);
  };

  return (
    <>
      {resources.map((r, index) => (
        <React.Fragment key={r.id}>
          <div key={r.id} className="d-flex flex-row flex-nowrap justify-content-between align-items-center gap-2">
            <div className="pb-1 overflow-hidden d-flex align-items-center">
              <FontAwesomeIcon
                icon={r.is_file ? faFile : faLink}
                className="text-primary me-2 align-middle"
                style={{ width: '20px', height: '20px' }}
              />
              {canShowVideo(r) ? (
                <a href={r.download_link} rel="noreferrer" onClick={(e) => handleShowVideo(e, r)} className="d-inline-block align-top text-truncate">
                  {r.filename}
                </a>
              ) : (
                <a href={r.download_link} target="_blank" rel="noreferrer" className="d-inline-block align-top text-truncate">
                  {r.filename}
                </a>
              )}
            </div>
            {typeof handleDelete === 'function' && canEdit && !isDelegatedRecord(r) && (
              <a onClick={() => handleDelete(r.id)} role="button">
                <FontAwesomeIcon icon={faTrashCan} color="#666" />
              </a>
            )}
          </div>
          {index !== resources.length - 1 && <hr style={{ margin: '5px 0' }} />}
        </React.Fragment>
      ))}
    </>
  );
};

export default ResourcesList;
