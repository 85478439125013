import React, { createContext, useState, useContext } from 'react';
import ConfirmDialog from './confirm_dialog';

const dialogPresets = {
  success: {
    icon: 'success',
  },
  info: {
    icon: 'info',
  },
  warning: {
    icon: 'warning',
  },
};

const ConfirmDialogContext = createContext();

export const ConfirmDialogProvider = ({ children }) => {
  const [dialogConfig, setDialogConfig] = useState({
    isOpen: false,
    title: 'Confirmation Required',
    description: 'Are you sure you want to proceed with this action?',
    actions: [],
  });

  const openDialog = (config) => {
    const preset = dialogPresets[config.type || 'warning'] || {};
    setDialogConfig({
      ...dialogConfig,
      ...preset,
      ...config,
      isOpen: true,
    });
  };

  const closeDialog = () => {
    setDialogConfig((prevConfig) => ({ ...prevConfig, isOpen: false }));
  };

  return (
    <ConfirmDialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}
      <ConfirmDialog {...dialogConfig} onClose={closeDialog} />
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirmDialog = () => {
  const context = useContext(ConfirmDialogContext);
  if (!context) {
    throw new Error('useConfirmDialog must be used within a ConfirmDialogProvider');
  }

  const { openDialog, closeDialog } = context;

  const showConfirmDialog = (config) => {
    openDialog(config);
  };

  const hideConfirmDialog = () => {
    closeDialog();
  };

  return {
    showConfirmDialog,
    hideConfirmDialog,
  };
};
