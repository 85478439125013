import { errorMessage } from '../../helpers/rails_errors';

export const alertError = ({ error = null }) => {
  let $alert = document.getElementById('jsFlashError');
  let $alertContent = document.getElementById('jsFlashErrorContent');
  if ($alert && $alertContent) {
    $alertContent.textContent = error ? errorMessage(error) : '';
    $alert.classList.add(error ? 'show' : 'hide');
    $alert.classList.remove(error ? 'hide' : 'show');
  }
  return null;
};

const AlertError = (data = {}) => alertError(data);

export default AlertError;
