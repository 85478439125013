import { useState, useCallback } from 'react';

function usePersistColumnSizes(tableName, initialColumns) {
  const getLocalStorageKey = useCallback(() => {
    return `attuned-${tableName}_columnWidths`;
  }, [tableName]);

  const [columnWidths, setColumnWidths] = useState(() => {
    const savedWidths = localStorage.getItem(getLocalStorageKey());
    return savedWidths ? JSON.parse(savedWidths) : {};
  });

  const handleColumnResize = useCallback(
    (params) => {
      const field = params.colDef.field;
      const newWidth = params.width;

      setColumnWidths((prevColumns) => {
        const newColumns = { ...prevColumns, [field]: newWidth };
        localStorage.setItem(getLocalStorageKey(), JSON.stringify(newColumns));
        return newColumns;
      });
    },
    [tableName]
  );

  const adjustedColumns = initialColumns.map((column) => ({
    ...column,
    width: columnWidths[column.field] || column.width,
    flex: columnWidths[column.field] ? 0 : column.flex,
    resizable: column.resizable !== undefined ? column.resizable : true,
  }));

  const resetColumnWidths = useCallback(() => {
    localStorage.removeItem(getLocalStorageKey());
    setColumnWidths({});
  }, [tableName]);

  return [adjustedColumns, handleColumnResize, resetColumnWidths];
}

export default usePersistColumnSizes;
