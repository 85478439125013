import React from 'react';
import { RichTextReadOnly } from 'mui-tiptap';
import useExtensions from './use_extensions';
import { Box } from '@mui/material';

/**
 * A read-only rich text component.
 * See: https://github.com/sjdemartini/mui-tiptap?tab=readme-ov-file#render-read-only-rich-text-content
 * @param initialContent
 * @param {Object} collaboration - An object containing the collaboration settings.
 * @param {String} variant - The style variant to apply to the component.
 * @param {Object} customStyle - Custom styles to apply to the component.
 * @param {String} customClassName - Custom class names to apply to the component.
 * @returns {Element}
 * @constructor
 */
const ReadOnlyRichText = ({ initialContent, variant = 'stylePrimary', customStyle, customClassName = '' }) => {
  const extensions = useExtensions({
    placeholder: '',
    collaboration: { enabled: false, user: {}, provider: null },
  });
  const styleVariants = {
    styleOutline: {
      border: '1px solid #ccc',
      padding: '15px 10px',
      borderRadius: '8px',
    },
    stylePrimary: {
      backgroundColor: 'rgba(227, 235, 240, 0.35)',
      padding: '15px 10px',
      borderRadius: '8px',
    },
  };

  const style = { ...styleVariants[variant], ...customStyle };
  const className = `editor-container -readonly ${customClassName}`;

  return (
    <Box className={className} sx={style}>
      <RichTextReadOnly content={initialContent} extensions={extensions} />
    </Box>
  );
};

export default ReadOnlyRichText;
