import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';
import { themeAttuned } from '../../constants/theme';
import { Snackbar, Alert } from '@mui/material';

/**
 * SnackMessage component based on Material UI Snackbar
 * @param isOpenProp
 * @param {number} duration - duration in milliseconds
 * @param {string} severity - one of {'error', 'warning', 'info', 'success'}
 * @param {string} variant - one of {'filled', 'outlined', 'standard'}
 * @param {string} message - message to display
 * @param {function} handleClose - callback function when the Snackbar is closed
 * @param {object} anchorOrigin - position of the Snackbar
 * @returns {Element}
 * @constructor
 */
const SnackMessage = ({
  isOpen: isOpenProp,
  duration = 4000,
  severity = 'info',
  variant = 'filled',
  message,
  handleClose = null,
  anchorOrigin = { vertical: 'bottom', horizontal: 'left' },
}) => {
  const [localOpen, setLocalOpen] = useState(isOpenProp);

  useEffect(() => {
    setLocalOpen(isOpenProp);
  }, [isOpenProp]);

  const handleLocalClose = () => {
    setLocalOpen(false);
    if (handleClose && typeof handleClose === 'function') {
      setTimeout(handleClose, 100);
    }
  };

  return (
    <ThemeProvider theme={themeAttuned}>
      <Snackbar open={localOpen} autoHideDuration={duration} anchorOrigin={anchorOrigin} onClose={handleLocalClose}>
        <Alert
          severity={severity}
          variant={variant}
          sx={{
            width: '100%',
          }}
          onClose={handleLocalClose}
        >
          {message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

SnackMessage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
  duration: PropTypes.number,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
};

export default SnackMessage;
