import React from 'react';
import { useRichTextEditorContext, MenuButton } from 'mui-tiptap';
import TitleIcon from '@mui/icons-material/Title';

/**
 * A menu button component that toggles the title style on and off.
 * Recommendation: Do not include "Heading" when using this component.
 * @param props
 * @returns {Element}
 * @constructor
 */
const MenuButtonToggleTitle = (props) => {
  const editor = useRichTextEditorContext();

  const toggleTitle = () => {
    const isActive = editor.isActive('heading', { level: 1 });
    if (isActive) {
      editor.chain().focus().setParagraph().run();
    } else {
      editor.chain().focus().toggleHeading({ level: 1 }).run();
    }
  };

  return (
    <MenuButton
      tooltipLabel={'Title'}
      IconComponent={TitleIcon}
      selected={editor?.isActive('heading', { level: 1 }) ?? false}
      disabled={!editor?.isEditable || !editor.can().toggleHeading({ level: 1 })}
      onClick={toggleTitle}
      {...props}
    />
  );
};

export default MenuButtonToggleTitle;
