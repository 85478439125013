import { useMemo } from 'react';

import Collaboration from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import { Blockquote } from '@tiptap/extension-blockquote';
import { Bold } from '@tiptap/extension-bold';
import { BulletList } from '@tiptap/extension-bullet-list';
import { Color } from '@tiptap/extension-color';
import { Document } from '@tiptap/extension-document';
import { Dropcursor } from '@tiptap/extension-dropcursor';
import { Gapcursor } from '@tiptap/extension-gapcursor';
import { HardBreak } from '@tiptap/extension-hard-break';
import { Heading } from '@tiptap/extension-heading';
import { Highlight } from '@tiptap/extension-highlight';
import { History } from '@tiptap/extension-history';
import { HorizontalRule } from '@tiptap/extension-horizontal-rule';
import { Italic } from '@tiptap/extension-italic';
import { Link } from '@tiptap/extension-link';
import { ListItem } from '@tiptap/extension-list-item';
import { OrderedList } from '@tiptap/extension-ordered-list';
import { Paragraph } from '@tiptap/extension-paragraph';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Strike } from '@tiptap/extension-strike';
import { TaskItem } from '@tiptap/extension-task-item';
import { TaskList } from '@tiptap/extension-task-list';
import { Text } from '@tiptap/extension-text';
import { TextAlign } from '@tiptap/extension-text-align';
import { TextStyle } from '@tiptap/extension-text-style';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import { FontFamily } from '@tiptap/extension-font-family';

import { FontSize, LinkBubbleMenuHandler, TableImproved } from 'mui-tiptap';

const COLLABORATION_CURSOR_COLORS = ['#FFD1DC', '#E6E6FA', '#FFDAB9', '#98FF98', '#B0E0E6', '#F08080', '#FFFF99', '#89CFF0', '#C4C3D0', '#98FB98'];

const CustomLinkExtension = Link.extend({
  inclusive: false,
});

const useExtensions = ({ placeholder, collaboration, isTableResizable }) => {
  return useMemo(() => {
    const extentions = [
      TableImproved.configure({
        resizable: isTableResizable,
      }),
      TableRow,
      TableHeader,
      TableCell,

      TextAlign.configure({
        types: ['heading', 'paragraph', 'textStyle'],
      }),

      BulletList,
      Document,
      HardBreak,
      ListItem,
      OrderedList,
      Paragraph,
      Text,

      // Blockquote must come after Bold, since we want the "Cmd+B" shortcut to
      // have lower precedence than the Blockquote "Cmd+Shift+B" shortcut.
      // Otherwise using "Cmd+Shift+B" will mistakenly toggle the bold mark.
      // (See https://github.com/ueberdosis/tiptap/issues/4005,
      // https://github.com/ueberdosis/tiptap/issues/4006)
      Bold,
      Blockquote,

      Italic,
      Strike,
      CustomLinkExtension.configure({
        autolink: true,
        linkOnPaste: true,
        openOnClick: false,
      }),
      LinkBubbleMenuHandler,

      Gapcursor,
      Heading.configure({
        levels: [1, 2, 3],
        HTMLAttributes: {
          class: 'rte-heading',
        },
      }),
      TextStyle,
      Color,
      FontFamily.configure({
        types: ['heading', 'paragraph', 'textStyle'],
        // defaultFontFamily: 'BrandonText, Roboto, Helvetica, Arial, sans-serif',
      }),
      FontSize.configure({
        sizes: [12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72],
      }),
      Highlight.configure({ multicolor: true }),
      HorizontalRule,
      Dropcursor,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Placeholder.configure({
        placeholder,
      }),
    ];

    if (collaboration.enabled) {
      extentions.push(
        ...[
          Collaboration.configure({
            document: collaboration.provider.document,
          }),
          CollaborationCursor.configure({
            provider: collaboration.provider,
            user: {
              name: collaboration.user,
              color: COLLABORATION_CURSOR_COLORS[Math.floor(Math.random() * 10)],
            },
          }),
        ]
      );
    } else {
      extentions.push(History);
    }

    return extentions;
  }, [placeholder]);
};

export default useExtensions;
