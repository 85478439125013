import React, { useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { closeButtonSx } from '../../constants/dialog';

const VideoDialog = ({ resource, isOpen, onClose }) => {
  const fileName = resource.filename;
  const url = resource.download_link;

  const getMimeType = useMemo(() => {
    const extension = fileName.split('.').pop();
    switch (extension) {
      case 'mp4':
        return 'video/mp4';
      case 'avi':
        return 'video/avi';
      case 'flv':
        return 'video/flv';
      case 'wmv':
        return 'video/wmv';
      case 'mov':
        return 'video/mov';
      default:
        return 'video/mp4';
    }
  }, [fileName]);

  return (
    <Dialog fullWidth open={isOpen} maxWidth="sm">
      <DialogTitle>{fileName}</DialogTitle>
      <IconButton aria-label="close" onClick={onClose} sx={closeButtonSx}>
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <video style={{ width: '100%', height: '100%', maxHeight: '60vh' }} controls>
          <source src={url} type={getMimeType} />
          Your browser does not support the video tag.
        </video>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default VideoDialog;
