import { getHeaders } from '../../headers';

export const createApi = (baseUrl) => {
  return {
    create: async (admNode, value, notes) => {
      const stripped = Object.fromEntries(
        Object.entries({
          adm_node_type: admNode.type,
          adm_node_id: admNode.id,
          value: value,
          notes: notes,
        }).filter(([_, v]) => v != null)
      );
      const response = await fetch(`${baseUrl}/responses`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
          response: stripped,
        }),
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    },
  };
};
