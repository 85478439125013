import dayjs from 'dayjs';

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '../../../helpers/date_utils';
import { GridEditSingleSelectCell, useGridApiContext } from '@mui/x-data-grid-pro';
import Attachments from './attachments';
import { Link } from '@mui/material';
const labelMap = {
  in_process: 'In Progress',
  mastered: 'Mastered',
};

function CustomIndicatorEditComponent(props) {
  const apiRef = useGridApiContext();

  const handleValueChange = async () => {
    await apiRef.current.setEditCellValue({
      id: props.id,
      field: 'aspect_name',
      value: '',
    });
  };
  return <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />;
}

const useGoalColumns = ({ leads, goalIndicators, goalTypes, showStatus = true, showDescriptors, isEditable = true, configType = 'allGoals' }) => {
  const leadOptions = useMemo(() => leads?.map((lead) => ({ id: lead.id, full_name: lead.full_name })), [leads]);
  const indicatorOptions = useMemo(() => goalIndicators?.map((indicator) => ({ id: indicator.id, name: indicator.name })), [goalIndicators]);
  const goalTypeOptions = useMemo(() => goalTypes?.map((type) => ({ id: type.id, name: type.name })), [goalTypes]);
  const statusOptions = useMemo(() => Object.keys(labelMap)?.map((key) => ({ id: key, label: labelMap[key] })), []);

  const descriptorOptions = (selectedIndicatorId) => {
    const selectedIndicator = goalIndicators.find((indicator) => indicator.id === selectedIndicatorId);
    return selectedIndicator ? selectedIndicator.summary_indicators?.map((descriptor) => ({ id: descriptor.id, name: descriptor.name })) : [];
  };

  const defaultField = (field, headerName, flex = 1) => ({
    field: field,
    headerName: headerName,
    flex: flex,
  });

  const idField = ({ headerName = 'ID' }) => ({
    field: 'id',
    headerName: headerName,
    width: 50,
  });

  const goalTypeField = ({ headerName = 'Goal Type', valueOptions = [], editable = false, flex = 1 }) => ({
    ...defaultField('goal_type', headerName, flex),
    editable: editable,
    type: 'singleSelect',
    valueOptions: valueOptions,
    valueFormatter: (value, row) => (row.goal_type ? row.goal_type.name : ''),
    getOptionValue: (option) => option.id,
    getOptionLabel: (option) => option.name,
    valueGetter: (value, row) => row.goal_type.id,
    valueSetter: (value, row) => ({ ...row, goal_type: valueOptions.find((option) => option.id === value) }),
  });

  const observableField = ({ headerName = 'Teacher', flex = 1 }) => ({
    ...defaultField('observable', headerName, flex),
    valueFormatter: (value) => value.name,
    renderCell: (params) => {
      return params.value.dashboard_url ? <Link href={params.value.dashboard_url}>{params.formattedValue}</Link> : params.formattedValue;
    },
    sortComparator: (v1, v2, param1, param2) => {
      const name1 = param1.api.getRow(param1.id)?.observable?.name ?? '';
      const name2 = param2.api.getRow(param2.id)?.observable?.name ?? '';

      return name1.localeCompare(name2);
    },
  });

  const descriptionField = ({ headerName = 'Description', editable = false, flex = 1 }) => ({
    ...defaultField('description', headerName, flex),
    editable: editable,
    type: 'string',
    valueFormatter: (value) => value || '',
  });

  const indicatorField = ({ headerName = 'Indicator', valueOptions = [], editable = false, flex = 1 }) => ({
    ...defaultField('aspect_name', headerName, flex),
    editable: editable,
    type: 'singleSelect',
    valueOptions: valueOptions,
    valueFormatter: (value, row) => (row.aspect ? row.aspect.name : row.aspect),
    getOptionValue: (option) => option.id,
    getOptionLabel: (option) => option.name,
    valueGetter: (value, row) => row.aspect.id,
    valueSetter: (value, row) => ({ ...row, aspect: valueOptions.find((option) => option.id === value) }),
    renderEditCell: (params) => <CustomIndicatorEditComponent {...params} />,
  });

  const descriptorField = ({ headerName = 'Descriptor', editable = false, flex = 1 }) => ({
    ...defaultField('summary_indicator_name', headerName, flex),
    editable: editable,
    type: 'singleSelect',
    valueOptions: ({ row }) => {
      if (!row) {
        return [];
      }
      return descriptorOptions(row.aspect?.id);
    },
    valueFormatter: (value, row) => {
      return row.summary_indicator ? row.summary_indicator.name : '';
    },
    getOptionValue: (option) => option?.id || '',
    getOptionLabel: (option) => option?.name || '',
    valueGetter: (value, row) => row.summary_indicator?.id || '',
    valueSetter: (value, row) => {
      const descriptors = descriptorOptions(row.aspect?.id);
      return { ...row, summary_indicator: descriptors.find((option) => option.id === value) || null };
    },
  });

  const statusField = ({ headerName = 'Status', valueOptions = [], editable = false, flex = 1 }) => ({
    ...defaultField('status', headerName, flex),
    type: 'singleSelect',
    editable: editable,
    valueOptions: valueOptions,
    valueFormatter: (value, row) => (value ? labelMap[value] : ''),
    getOptionValue: (option) => option.id,
    getOptionLabel: (option) => option.label,
  });

  const createdAtField = ({ headerName = 'Date', editable = false, flex = 1 }) => ({
    ...defaultField('created_at', headerName, flex),
    type: 'date',
    valueFormatter: (value) => formatDate(value),
    editable: editable,
  });

  const dueDateField = ({ headerName = 'Due Date', editable = false, flex = 1 }) => ({
    ...defaultField('due_date', headerName, flex),
    type: 'date',
    valueFormatter: (value) => {
      return value ? dayjs(value).format('MM/DD/YYYY') : null;
    },
    editable: editable,
    valueGetter: (value, row) => {
      return row.due_date || null;
    },
    valueSetter: (value, row) => {
      const date = dayjs(value).format('YYYY-MM-DD');
      return { ...row, due_date: date };
    },
  });

  const coachField = ({ headerName = 'Lead', valueOptions = [], editable = false, flex = 1 }) => ({
    ...defaultField('coach', headerName, flex),
    type: 'singleSelect',
    editable: editable,
    valueOptions: valueOptions,
    valueFormatter: (value, row) => (row.coach ? row.coach.full_name : ''),
    getOptionValue: (option) => option.id,
    getOptionLabel: (option) => option.full_name,
    valueGetter: (value, row) => value?.id,
    valueSetter: (value, row) => ({ ...row, coach: valueOptions.find((option) => option.id === value) }),
  });

  const resourcesField = ({ headerName = 'Resources', editable = false, flex = 1 }) => ({
    ...defaultField('attachments', headerName, flex),
    editable: editable,
    valueFormatter: (value) => value || '',
    renderCell: (params) => <Attachments goal={params.row} />,
    sortable: false,
  });

  const columns = useMemo(() => {
    if (configType === 'singleObservable') {
      return [
        createdAtField({ flex: 0.5 }),
        goalTypeField({ editable: isEditable, valueOptions: goalTypeOptions, flex: 0.7 }),
        descriptionField({ editable: isEditable, flex: 1.3 }),
        indicatorField({ editable: isEditable, valueOptions: indicatorOptions, flex: 1 }),
        ...(showDescriptors ? [descriptorField({ editable: isEditable, flex: 1 })] : []),
        ...(showStatus ? [statusField({ editable: isEditable, valueOptions: statusOptions, flex: 0.5 })] : []),
        coachField({ editable: isEditable, valueOptions: leadOptions, flex: 0.6 }),
        resourcesField({ editable: false, flex: 0.5 }),
      ];
    } else if (configType === 'singleObserver') {
      return [
        observableField({ flex: 1 }),
        createdAtField({ flex: 0.5 }),
        goalTypeField({ editable: isEditable, valueOptions: goalTypeOptions, flex: 0.7 }),
        descriptionField({ editable: isEditable, flex: 1.3 }),
        indicatorField({ editable: isEditable, valueOptions: indicatorOptions, flex: 1 }),
        ...(showDescriptors ? [descriptorField({ editable: isEditable, flex: 1 })] : []),
        ...(showStatus ? [statusField({ editable: isEditable, valueOptions: statusOptions, flex: 0.5 })] : []),
        resourcesField({ editable: false, flex: 1 }),
      ];
    } else {
      return [
        idField({}),
        observableField({ flex: 0.8 }),
        goalTypeField({ editable: isEditable, valueOptions: goalTypeOptions, flex: 0.7 }),
        descriptionField({ editable: isEditable, flex: 1.3 }),
        indicatorField({ editable: isEditable, valueOptions: indicatorOptions, flex: 1 }),
        ...(showDescriptors ? [descriptorField({ editable: isEditable, flex: 1 })] : []),
        ...(showStatus ? [statusField({ editable: isEditable, valueOptions: statusOptions, flex: 0.6 })] : []),
        dueDateField({ headerName: 'Due Date', editable: isEditable, flex: 0.5 }),
        coachField({ editable: isEditable, valueOptions: leadOptions, flex: 0.6 }),
      ];
    }
  }, [leadOptions, indicatorOptions, statusOptions, goalTypeOptions, showDescriptors, showStatus, configType]);

  return columns;
};

useGoalColumns.propTypes = {
  leads: PropTypes.array,
  goalIndicators: PropTypes.array,
  goalTypes: PropTypes.array,
  showStatus: PropTypes.bool,
  showDescriptors: PropTypes.bool,
  configType: PropTypes.string,
};

export default useGoalColumns;
