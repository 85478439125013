import ReadOnlyRichText from '../../../shared/rte/readonly_rich_text';
import React from 'react';

const RubricContent = ({ rubricHtml, show }) => {
  const style = {
    fontSize: '16px',
    marginTop: '10px',
  };

  if (!show) return null;

  return (
    <div style={style} className="w-100">
      <ReadOnlyRichText initialContent={rubricHtml} variant="noStyle" customClassName="is-rubric" />
    </div>
  );
};

export { RubricContent };
