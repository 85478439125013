import React from 'react';
import { useTheme } from '@mui/material';
import {
  MenuButtonAddTable,
  MenuButtonBlockquote,
  MenuButtonBold,
  MenuButtonUndo,
  MenuButtonRedo,
  MenuButtonBulletedList,
  MenuButtonItalic,
  MenuButtonStrikethrough,
  MenuButtonEditLink,
  MenuButtonHorizontalRule,
  MenuButtonOrderedList,
  MenuButtonRemoveFormatting,
  MenuButtonTaskList,
  MenuButtonTextColor,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  MenuSelectFontSize,
  MenuSelectTextAlign,
  MenuButtonHighlightColor,
  MenuSelectFontFamily,
} from 'mui-tiptap';

import MenuButtonToggleTitle from './menu_button_toggle_title';

const controlComponents = {
  MenuButtonAddTable: MenuButtonAddTable,
  MenuButtonBlockquote: MenuButtonBlockquote,
  MenuButtonBold: MenuButtonBold,
  MenuButtonUndo: MenuButtonUndo,
  MenuButtonRedo: MenuButtonRedo,
  MenuButtonBulletedList: MenuButtonBulletedList,
  MenuButtonItalic: MenuButtonItalic,
  MenuButtonStrikethrough: MenuButtonStrikethrough,
  MenuButtonEditLink: MenuButtonEditLink,
  MenuButtonHorizontalRule: MenuButtonHorizontalRule,
  MenuButtonOrderedList: MenuButtonOrderedList,
  MenuButtonRemoveFormatting: MenuButtonRemoveFormatting,
  MenuButtonTaskList: MenuButtonTaskList,
  MenuButtonTextColor: MenuButtonTextColor,
  MenuControlsContainer: MenuControlsContainer,
  MenuDivider: MenuDivider,
  MenuSelectHeading: MenuSelectHeading,
  MenuSelectFontSize: MenuSelectFontSize,
  MenuSelectTextAlign: MenuSelectTextAlign,
  MenuButtonToggleTitle: MenuButtonToggleTitle,
  MenuButtonHighlightColor: MenuButtonHighlightColor,
  MenuSelectFontFamily: MenuSelectFontFamily,
};

const Controls = ({ controlsConfig }) => {
  const theme = useTheme();
  const controlElements = () => {
    return controlsConfig.map((controlName, index) => {
      const ControlComponent = controlComponents[controlName];

      if (ControlComponent === MenuButtonTextColor) {
        return (
          <ControlComponent
            key={index}
            defaultTextColor={theme.palette.text.primary}
            swatchColors={[
              { value: '#000000', label: 'Black' },
              { value: '#ffffff', label: 'White' },
              { value: '#888888', label: 'Grey' },
              { value: '#ff0000', label: 'Red' },
              { value: '#ff9900', label: 'Orange' },
              { value: '#ffff00', label: 'Yellow' },
              { value: '#00d000', label: 'Green' },
              { value: '#0000ff', label: 'Blue' },
            ]}
          />
        );
      }

      if (ControlComponent === MenuButtonHighlightColor) {
        return (
          <ControlComponent
            key={index}
            swatchColors={[
              { value: '#595959', label: 'Dark grey' },
              { value: '#dddddd', label: 'Light grey' },
              { value: '#ffa6a6', label: 'Light red' },
              { value: '#ffd699', label: 'Light orange' },
              // Plain yellow matches the browser default `mark` like when using Cmd+Shift+H
              { value: '#ffff00', label: 'Yellow' },
              { value: '#99cc99', label: 'Light green' },
              { value: '#90c6ff', label: 'Light blue' },
              { value: '#8085e9', label: 'Light purple' },
            ]}
          />
        );
      }

      if (ControlComponent === MenuSelectFontFamily) {
        return (
          <ControlComponent
            key={index}
            options={[
              { label: 'Arial', value: 'Arial' },
              { label: 'BrandonText', value: 'BrandonText' },
              { label: 'Comic Sans', value: 'Comic Sans MS, Comic Sans' },
              { label: 'Courier New', value: 'Courier New' },
              { label: 'Cursive', value: 'cursive' },
              { label: 'Georgia', value: 'Georgia' },
              { label: 'Helvetica', value: 'Helvetica' },
              { label: 'Monospace', value: 'monospace' },
              { label: 'Roboto', value: 'Roboto' },
              { label: 'Serif', value: 'serif' },
              { label: 'Times New Roman', value: 'Times New Roman' },
              { label: 'Trebuchet MS ', value: 'Trebuchet MS ' },
              { label: 'Verdana', value: 'Verdana' },
            ]}
            // defaultValue={'BrandonText, Roboto, Helvetica, Arial, sans-serif'}
          />
        );
      }

      // rename the tooltip label
      if (ControlComponent === MenuButtonRemoveFormatting) {
        return <ControlComponent key={index} tooltipLabel="Clear formatting" />;
      }

      return ControlComponent ? <ControlComponent key={index} /> : null;
    });
  };

  return <MenuControlsContainer>{controlElements()}</MenuControlsContainer>;
};

export default Controls;
