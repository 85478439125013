import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AdmDescription from '../../shared/adm_description';
import { Box } from '@mui/material';

const hasResponseInTree = (admNode, observation) => {
  const response = observation.responses.find((response) => response.adm_node_type === admNode.type && response.adm_node_id === admNode.id);
  if (response && (response.value !== null || response.notes !== null)) {
    return true;
  }

  if (admNode.summary_indicators) {
    return admNode.summary_indicators.some((summaryIndicator) => hasResponseInTree(summaryIndicator, observation));
  }
  if (admNode.indicators) {
    return admNode.indicators.some((indicator) => hasResponseInTree(indicator, observation));
  }

  return false;
};

const isHqimInUseDefined = (hqim_in_use) => {
  return !!hqim_in_use || hqim_in_use === false;
};

const hideBasedOnConditionality = (hqim_in_use, config) => {
  if (!isHqimInUseDefined(hqim_in_use)) {
    return false;
  }
  if (config?.hqim_conditionality === 'hide_if_hqim_yes') {
    return hqim_in_use === true;
  }
  if (config?.hqim_conditionality === 'hide_if_hqim_no') {
    return hqim_in_use === false;
  }
  return false;
};

const isDisabledIndicatorQuestion = (indicator, contentAreaId) => {
  return contentAreaId === null && indicator.content_area_id !== null;
};

const isDisabledNode = (node, response, hqim_in_use) => {
  const config = node.survey_config;
  if (config?.hqim_conditionality && !isHqimInUseDefined(hqim_in_use)) {
    return true;
  }
  if (config?.hqim_conditionality === 'yes_and_lock_if_hqim_yes') {
    return hqim_in_use === true;
  }
  return false;
};

const NotesInput = ({ observation, admNode }) => {
  const response = observation?.responses?.find((response) => response.adm_node_type === admNode.type && response.adm_node_id === admNode.id);
  return response?.notes ? (
    <Box className="mt-3 mb-3">
      <div className={'rte-container'} dangerouslySetInnerHTML={{ __html: response?.notes }} />
    </Box>
  ) : null;
};

const QuestionInput = ({ observation, config, createResponse, admNode, disabled = false, response }) => {
  if (config.question_type.includes('_point_scale')) {
    const numPoints = parseInt(config.question_type.replace('_point_scale', ''));
    return (
      <PointScaleInput
        numPoints={numPoints}
        includeNA
        observation={observation}
        config={config}
        createResponse={createResponse}
        admNode={admNode}
        value={response?.value}
        disabled={disabled}
      />
    );
  } else if (config.question_type === '0_to_1') {
    return (
      <PointScaleInput
        points={[0, 0.5, 1]}
        observation={observation}
        config={config}
        createResponse={createResponse}
        admNode={admNode}
        value={response?.value}
        disabled={disabled}
      />
    );
  } else if (config.question_type === '0_to_3') {
    return (
      <PointScaleInput
        points={[0, 1, 2, 3]}
        observation={observation}
        config={config}
        createResponse={createResponse}
        admNode={admNode}
        value={response?.value}
        disabled={disabled}
      />
    );
  } else {
    switch (config.question_type) {
      case 'yes_no_na':
        return (
          <YesNoNaInput
            config={config}
            createResponse={createResponse}
            admNode={admNode}
            observation={observation}
            value={response?.value}
            disabled={disabled}
          />
        );
      case 'observed_not_observed':
        return (
          <ObservedNotObservedInput
            config={config}
            createResponse={createResponse}
            admNode={admNode}
            observation={observation}
            value={response?.value}
            disabled={disabled}
          />
        );
      case 'number':
        return (
          <div style={{ marginTop: '10px !important' }}>
            <TextField
              id="outlined-number"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={response?.value}
              disabled={disabled}
            />
          </div>
        );
      case 'percentage':
        return (
          <TextField
            id="outlined-number"
            label="Percentage"
            type="number"
            defaultValue={response?.value}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={disabled}
          />
        );
      case 'text':
        return (
          <TextField
            id="outlined-number"
            className="w-100 mt-3 mb-3"
            label={admNode.name + ' Notes'}
            defaultValue={response?.value}
            InputLabelProps={{
              shrink: true,
            }}
            disabled={disabled}
          />
        );
      default:
        return null;
    }
  }
};

const YesNoNaInput = ({ observation, config, createResponse, admNode, value, disabled }) => {
  return (
    <FormControl>
      <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" value={value || ''} name="radio-buttons-group" sx={{ flexWrap: 'nowrap' }}>
        <FormControlLabel value="yes" disabled={disabled} control={<Radio />} label="Yes" />
        <FormControlLabel value="no" disabled={disabled} control={<Radio />} label="No" />
        <FormControlLabel value="na" disabled={disabled} control={<Radio />} label="N/A" />
      </RadioGroup>
    </FormControl>
  );
};

const ObservedNotObservedInput = ({ observation, config, createResponse, admNode, value, disabled }) => {
  return (
    <FormControl>
      <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" value={value || ''} name="radio-buttons-group" sx={{ flexWrap: 'nowrap' }}>
        <FormControlLabel value="yes" disabled={disabled} control={<Radio />} label="Observed" />
        <FormControlLabel value="no" disabled={disabled} control={<Radio />} label="No (developing)" />
        <FormControlLabel value="na" disabled={disabled} control={<Radio />} label="N/A" />
      </RadioGroup>
    </FormControl>
  );
};

const PointScaleInput = ({ numPoints, points, includeNA, observation, config, createResponse, admNode, value, disabled }) => {
  const values = points ? points : Array.from({ length: numPoints }, (_, i) => i + 1).reverse();
  return (
    <FormControl>
      <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" value={value || ''} name="radio-buttons-group" sx={{ flexWrap: 'nowrap' }}>
        {values.map((val) => (
          <FormControlLabel key={val} value={val} disabled={disabled} control={<Radio />} label={val} labelPlacement="start" />
        ))}
        {includeNA && <FormControlLabel value="na" disabled={disabled} control={<Radio />} label="N/A" labelPlacement="start" />}
      </RadioGroup>
    </FormControl>
  );
};

const Indicator = ({ indicator, observation, createResponse, contentAreaId, readOnly, showAdmNumbers }) => {
  if (hideBasedOnConditionality(observation?.hqim_in_use, indicator.survey_config)) {
    return null;
  }

  const hasResponsesInTree = hasResponseInTree(indicator, observation);
  if (!hasResponsesInTree) {
    return null;
  }

  const response = observation?.responses?.find((response) => response.adm_node_type === 'Care::Indicator' && response.adm_node_id === indicator.id);

  return (
    <div className={`indicator py-2`}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'nowrap', gap: 2 }}>
        <div className="d-flex align-items-start">
          <span style={{ color: '#333' }}>{showAdmNumbers && <strong className="me-3">{indicator.number}</strong>}</span>
          <div style={{ color: '#666' }}>{indicator.description}</div>
        </div>
        <div style={{ flexShrink: 0 }}>
          <QuestionInput
            config={indicator.survey_config}
            observation={observation}
            createResponse={createResponse}
            admNode={{ type: 'Care::Indicator', id: indicator.id }}
            response={response}
            disabled={
              isDisabledIndicatorQuestion(indicator, contentAreaId) || isDisabledNode(indicator, response, observation?.hqim_in_use) || readOnly
            }
          />
        </div>
      </div>
    </div>
  );
};

const SummaryIndicator = ({ summaryIndicator, createResponse, observation, contentAreaId, readOnly, showAdmNumbers }) => {
  const nodeConfig = summaryIndicator.survey_config;
  if (hideBasedOnConditionality(observation?.hqim_in_use, nodeConfig)) {
    return null;
  }

  const hasResponsesInTree = hasResponseInTree(summaryIndicator, observation);
  if (!hasResponsesInTree) {
    return null;
  }

  const response = observation?.responses?.find(
    (response) => response.adm_node_type === 'Care::SummaryIndicator' && response.adm_node_id === summaryIndicator.id
  );

  return (
    <div className={`summary-indicator px-4 py-3`} style={{ marginTop: '8px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'nowrap', gap: 2 }}>
        <div>
          <div className="d-flex" style={{ margin: 0, padding: 0 }}>
            <h5 className="headlineColor" style={{ margin: 0, padding: '4px 0 0 0' }}>
              <strong>
                {showAdmNumbers && summaryIndicator.number && <span>{summaryIndicator.number}&nbsp;</span>}
                {summaryIndicator.name}
              </strong>
            </h5>
          </div>
          <AdmDescription description={summaryIndicator.description} style={{ margin: 0, padding: 0 }} />
        </div>
        <div style={{ flexShrink: 0 }}>
          <QuestionInput
            config={nodeConfig}
            observation={observation}
            admNode={{ type: 'Care::SummaryIndicator', id: summaryIndicator.id }}
            createResponse={createResponse}
            response={response}
            disabled={isDisabledNode(summaryIndicator, response, observation?.hqim_in_use) || readOnly}
          />
        </div>
      </div>
      {summaryIndicator.survey_config.allow_notes && (
        <NotesInput observation={observation} admNode={{ type: 'Care::SummaryIndicator', id: summaryIndicator.id, name: summaryIndicator.name }} />
      )}
      <div className="pt-2">
        {summaryIndicator.indicators
          .filter((i) => {
            return contentAreaId === null || i.content_area_id === null || i.content_area_id === contentAreaId;
          })
          .map((indicator) => (
            <Indicator
              key={indicator.id}
              indicator={indicator}
              observation={observation}
              createResponse={createResponse}
              contentAreaId={contentAreaId}
              readOnly={readOnly}
              showAdmNumbers={showAdmNumbers}
            />
          ))}
      </div>
    </div>
  );
};

const Aspect = ({ aspect, observation, createResponse, contentAreaId, readOnly, showAdmNumbers, dialogRef }) => {
  const hasResponsesInTree = hasResponseInTree(aspect, observation);
  if (!hasResponsesInTree) {
    return null;
  }

  const nodeConfig = aspect.survey_config;
  if (hideBasedOnConditionality(observation?.hqim_in_use, nodeConfig)) {
    return null;
  }

  const response = observation?.responses?.find((response) => response.adm_node_type === 'Care::Aspect' && response.adm_node_id === aspect.id);

  return (
    <div className={`mt-3 aspect`} style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'nowrap' }}>
        <h3 className={'headlineColor'}>
          {showAdmNumbers && aspect.number && <span>{aspect.number}&nbsp;</span>}
          {aspect.name}
        </h3>
        <div style={{ flexShrink: 0 }}>
          <QuestionInput
            config={nodeConfig}
            observation={observation}
            createResponse={createResponse}
            admNode={{ type: 'Care::Aspect', id: aspect.id, name: aspect.name }}
            response={response}
            disabled={isDisabledNode(aspect, response, observation?.hqim_in_use) || readOnly}
          />
        </div>
      </div>

      {nodeConfig.question_type !== 'title' && <div style={{ fontSize: 16, width: '60% !important', color: '#666' }}>{aspect.description}</div>}

      {aspect.survey_config.allow_notes && (
        <NotesInput observation={observation} admNode={{ type: 'Care::Aspect', id: aspect.id, name: aspect.name }} />
      )}

      {aspect?.summary_indicators.length > 0 && (
        <div style={{ backgroundColor: '#F5F8FA', marginTop: '8px' }}>
          {aspect.summary_indicators.map((summaryIndicator) => (
            <SummaryIndicator
              key={summaryIndicator.id}
              summaryIndicator={summaryIndicator}
              observation={observation}
              createResponse={createResponse}
              contentAreaId={contentAreaId}
              readOnly={readOnly}
              showAdmNumbers={showAdmNumbers}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const AspectCategory = ({ aspectCategory, observation, createResponse, contentAreaId, readOnly, showAdmNumbers, dialogRef }) => {
  return (
    <div className="mt-5 chapter" key={aspectCategory.id}>
      <h1 className="headlineColor">{aspectCategory.name}</h1>
      {aspectCategory.survey_config.allow_notes && (
        <NotesInput
          observation={observation}
          admNode={{
            type: 'Care::AspectCategory',
            id: aspectCategory.id,
            name: aspectCategory.name,
          }}
        />
      )}
      <div className="mt-3">
        {aspectCategory.aspects.map((aspect) => (
          <Aspect
            key={aspect.id}
            aspect={aspect}
            observation={observation}
            createResponse={createResponse}
            contentAreaId={contentAreaId}
            readOnly={readOnly}
            showAdmNumbers={showAdmNumbers}
            dialogRef={dialogRef}
          />
        ))}
      </div>
    </div>
  );
};

export default ({ ...props }) => {
  const { observation, admTree, createResponse, contentAreaId, readOnly, showAdmNumbers, dialogRef } = props;
  return (
    <div className="mt-3 pb-5 adm-selector">
      {admTree?.map(
        (aspectCategory) =>
          (aspectCategory?.aspects?.length > 0 || (aspectCategory.survey_config.allow_notes && aspectCategory.survey_config.required)) && (
            <AspectCategory
              key={aspectCategory.id}
              aspectCategory={aspectCategory}
              observation={observation}
              createResponse={createResponse}
              contentAreaId={contentAreaId}
              readOnly={readOnly}
              showAdmNumbers={showAdmNumbers}
              dialogRef={dialogRef}
            />
          )
      )}
    </div>
  );
};
