import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { LinkModalStyles } from '../../constants/react_modal_styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromBracket, faLinkSimple } from '@fortawesome/pro-regular-svg-icons';

export default (props) => {
  const fileRef = useRef();
  const linkDisplayRef = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [linkDisplayText, setLinkDisplayText] = useState('');
  const [errors, setErrors] = useState({});

  const isDisabled = props.isDisabled || false;

  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  const handleChange = (e) => {
    const [file] = e.target.files;
    const data = new FormData();
    data.append('file', file);
    const url = props.url;
    fetch(url, {
      method: 'PUT',
      body: data,
    })
      .then((resp) => resp.json())
      .then((data) => props.handleUpload(data));
  };

  const resetModal = () => {
    setIsModalOpen(false);
    setLinkUrl('');
    setLinkDisplayText('');
    setErrors({});
  };

  const createLink = () => {
    const url = props.url;
    const data = new FormData();
    data.append('url', linkUrl);
    data.append('display_text', linkDisplayText);
    fetch(url, {
      method: 'PUT',
      body: data,
    })
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        if (data.errors) {
          setErrors(data.errors);
        } else {
          resetModal();
          props.handleUpload(data);
        }
      });
  };

  const afterOpenModal = () => {
    linkDisplayRef.current.focus();
  };

  const actionStyle = {
    color: '#707070',
    textDecoration: 'none',
  };

  return (
    <>
      <a onClick={() => !isDisabled && fileRef.current.click()} className={props.className + ' d-inline-block'} style={actionStyle}>
        <FontAwesomeIcon icon={faArrowUpFromBracket} />
      </a>
      <input ref={fileRef} onChange={handleChange} multiple={false} type="file" hidden />
      {!props.hideLinkUpload && (
        <a onClick={() => !isDisabled && setIsModalOpen(true)} className="ms-2 d-inline-block" style={actionStyle}>
          <FontAwesomeIcon icon={faLinkSimple} />
        </a>
      )}

      <Modal isOpen={isModalOpen} onAfterOpen={afterOpenModal} style={LinkModalStyles}>
        <h5 className="modal-title">Add Link</h5>
        <label className="mt-4 mb-2">Text to display</label>
        <input type="text" ref={linkDisplayRef} className="w-100" value={linkDisplayText} onChange={(e) => setLinkDisplayText(e.target.value)} />

        <label className="mt-4 mb-2">Link to document</label>
        <input className="w-100" value={linkUrl} onChange={(e) => setLinkUrl(e.target.value)} />

        {errors.url && <div className="error-text">Please enter a valid URL.</div>}
        {errors.base && <div className="error-text">Please enter a valid URL and display text.</div>}

        <div className="d-flex w-100 justify-content-end mt-5">
          <button className="btn btn-att-gray-small me-2" onClick={resetModal}>
            {' '}
            Cancel{' '}
          </button>
          <button className="btn btn-att-blue-small" onClick={createLink}>
            {' '}
            Save{' '}
          </button>
        </div>
      </Modal>
    </>
  );
};
