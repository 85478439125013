import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { useConfirmDialog } from '../../modals/confirm_dialog';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrashCan, faFloppyDisk, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { DataGridPro, useGridApiRef, GridActionsCellItem, GridRowModes } from '@mui/x-data-grid-pro';
import { Typography, Box, Stack, Button, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';

import { commonConfig } from '../../../helpers/data_grid/common_config';
import { multiLineConfig } from '../../../helpers/data_grid/multi_line_config';
import usePersistColumnSizes from '../../../helpers/data_grid/use_persisted_column_sizes';
import ResetColumnSizesMenu from '../../../helpers/data_grid/reset_column_sizes_menu';
import ActionStepsTable from '../action_steps/action_steps_table';
import ActionStepDialog from '../action_steps/action_steps_dialog';

import { updateGoal } from '../../../apis/care/goals/api';
import { createActionStep, updateActionStep } from '../../../apis/care/action_steps/api';
import { deleteGoal } from '../../../apis/care/goals/api';
import { deleteActionStep } from '../../../apis/care/action_steps/api';
import { gridStyles } from '../../../helpers/data_grid/common_grid_styles';
import { trackCzEvent } from '../../../helpers/track_cz_event';

export default function GoalsTable({
  title,
  actionStepsUrl,
  goals,
  leads,
  columnConfig,
  enableTableActions = false,
  enableActionSteps = false,
  canAddActionStep = false,
  hideDataAnalysisColumn = false,
  observableGoalsBaseUrl,
  goalsBaseUrl,
  tableName = 'goals',
  initialState = {},
}) {
  const theme = useTheme();
  const apiRef = useGridApiRef();
  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();
  const [goalsState, setGoalsState] = useState(goals);
  const [rowModesModel, setRowModesModel] = useState({});
  const [isAddActionStepDialogOpen, setIsAddActionStepDialogOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);

  useEffect(() => {
    setGoalsState(goals);
  }, [goals]);

  const sortedGoals = useMemo(() => {
    return [...goalsState].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  }, [goalsState]);

  // Save new action step or update existing action step
  const syncActionStepState = useCallback(
    (goalId, actionStep) => {
      setGoalsState((prevGoals) =>
        prevGoals.map((goal) => {
          if (goal.id === goalId) {
            const existingIndex = goal?.action_steps?.findIndex((step) => step.id === actionStep.id);
            if (existingIndex > -1) {
              // update existing action step
              const updatedActionSteps = [...goal.action_steps];
              updatedActionSteps[existingIndex] = actionStep;
              return { ...goal, action_steps: updatedActionSteps };
            } else {
              // add new action step
              return { ...goal, action_steps: [...goal.action_steps, actionStep] };
            }
          }
          return goal;
        })
      );
    },
    [goalsState, setGoalsState]
  );

  const handleSaveActionStep = async (payload) => {
    const goalId = payload?.goal_id;
    try {
      const response = await createActionStep(payload, actionStepsUrl);
      trackCzEvent('CAREAdd:ActionStep', `User added action step ${response.id}`, 1);
      syncActionStepState(goalId, response);
      setIsAddActionStepDialogOpen(false);
      setToastOpen(true);
      return true;
    } catch (error) {
      console.error('error', error);
      setToastOpen(false);
      return { errors: error };
    }
  };

  const handleUpdateActionStep = async (goalId, payload) => {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await updateActionStep(payload, actionStepsUrl);
      trackCzEvent('CAREEdit:ActionStep', `User updated action step ${response.id}`, 1);
      syncActionStepState(goalId, response);
      return true;
    } catch (error) {
      throw error;
    }
  };

  const handleDeleteActionStep = async (actionStepId, goalId) => {
    try {
      await deleteActionStep(actionStepId, actionStepsUrl);
      trackCzEvent('CAREDelete:ActionStep', `User deleted action step ${actionStepId}`, 1);
      setGoalsState((prevGoals) =>
        prevGoals.map((g) => {
          if (g.id === goalId) {
            return { ...g, action_steps: g.action_steps.filter((step) => step.id !== actionStepId) };
          }
          return g;
        })
      );
    } catch (error) {
      console.error('error', error);
    }
  };

  const handleAddActionStep = () => {
    event.preventDefault();
    setIsAddActionStepDialogOpen(true);
  };

  const getDetailPanelContent = useCallback(
    ({ row }) => {
      const hasActionSteps = row?.action_steps?.length > 0;
      return (
        <>
          <Box sx={{ p: 2 }}>
            {hasActionSteps && (
              <ActionStepsTable
                goal={row}
                leads={leads}
                canEdit={canAddActionStep}
                onUpdate={handleUpdateActionStep}
                onDelete={handleDeleteActionStep}
              />
            )}
            {canAddActionStep && (
              <>
                <Stack direction="row" spacing={1} sx={{ marginTop: 1 }} alignItems="center" justifyContent="flex-start">
                  <Button variant="outlined" color="primary" size="small" onClick={handleAddActionStep}>
                    Add Action Step
                  </Button>
                </Stack>
                <ActionStepDialog
                  title="Add Action Step"
                  goal={row}
                  leads={leads}
                  isOpen={isAddActionStepDialogOpen}
                  onSave={handleSaveActionStep}
                  onClose={() => setIsAddActionStepDialogOpen(false)}
                />
              </>
            )}
          </Box>
        </>
      );
    },
    [leads, actionStepsUrl, canAddActionStep, syncActionStepState, handleDeleteActionStep, handleUpdateActionStep, handleSaveActionStep]
  );

  const getDetailPanelHeight = React.useCallback(() => 'auto', []);

  const handleEditClick = useCallback(
    (id) => () => {
      const newModel = { ...rowModesModel, [id]: { mode: GridRowModes.Edit } };
      setRowModesModel(newModel);
    },
    []
  );

  const handleSaveClick = useCallback(
    (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    []
  );

  const handleViewDataAnalysisClick = useCallback((id) => () => {
    const url = goalsBaseUrl.replace(/[^\/]*$/, `reports/observations?goal_id=${id}`); // eslint-disable-line
    window.location.href = url;
  });

  // stop editing, triggered by:
  // Esc/Cancel - reverts changes
  // Tab/Enter/Save - saves changes
  const handleCancelClick = useCallback(
    (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    },
    []
  );

  const getActionColumn = (theme, headerName = 'Actions') => ({
    field: 'actions',
    type: 'actions',
    headerName: headerName,
    maxWidth: 170,
    flex: 0.7,
    cellClassName: 'actions',
    align: 'left',
    headerAlign: 'left',
    getActions: ({ id }) => {
      const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
      const actions = isInEditMode
        ? [
            <GridActionsCellItem
              key={`save-${id}`}
              icon={<FontAwesomeIcon icon={faFloppyDisk} color={theme.palette.success[600]} style={{ fontSize: '1.1rem' }} />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              key={`cancel-${id}`}
              icon={<FontAwesomeIcon icon={faXmark} color={theme.palette.grey[600]} style={{ fontSize: '1.1rem' }} />}
              label="Cancel"
              onClick={handleCancelClick(id)}
            />,
          ]
        : [
            <Button key={`data-analysis-${id}`} variant="contained" color="secondary" size="small" onClick={handleViewDataAnalysisClick(id)}>
              View
            </Button>,
            <GridActionsCellItem
              key={`edit-${id}`}
              icon={<FontAwesomeIcon icon={faPencil} color={theme.palette.grey[500]} style={{ fontSize: '1.1rem' }} />}
              label="Edit"
              onClick={handleEditClick(id)}
            />,
            <GridActionsCellItem
              key={`delete-${id}`}
              icon={<FontAwesomeIcon icon={faTrashCan} color={theme.palette.grey[500]} style={{ fontSize: '1.1rem' }} />}
              label="Delete"
              onClick={(e) => confirmDelete(id)}
            />,
          ];
      return actions;
    },
  });

  const getDataAnalysisColumn = (theme, headerName = 'Data Analysis') => ({
    field: 'data-analysis',
    type: 'actions',
    headerName: headerName,
    width: 150,
    cellClassName: 'data-analysis',
    getActions: ({ id }) => {
      return [
        <Button key={`data-analysis-${id}`} variant="contained" color="secondary" size="small" onClick={handleViewDataAnalysisClick(id)}>
          View
        </Button>,
      ];
    },
  });

  const columns = useMemo(() => {
    const config = [...columnConfig];
    if (enableTableActions) {
      config.push(getActionColumn(theme));
    } else if (!hideDataAnalysisColumn) {
      config.push(getDataAnalysisColumn(theme));
    }
    return config;
  }, [columnConfig, enableTableActions, hideDataAnalysisColumn, theme, rowModesModel]);

  const shouldSave = (updatedRow, originalRow) => {
    if (updatedRow.coach?.id !== originalRow.coach?.id) {
      return true;
    }
    if (updatedRow.status !== originalRow.status) {
      return true;
    }
    if (updatedRow.aspect?.id !== originalRow.aspect?.id) {
      return true;
    }
    if (updatedRow.summary_indicator?.id !== originalRow.summary_indicator?.id) {
      return true;
    }
    if (updatedRow.description !== originalRow.description) {
      return true;
    }
    if (updatedRow.goal_type?.id !== originalRow.goal_type?.id) {
      return true;
    }
    if (updatedRow.due_date !== originalRow.due_date) {
      return true;
    }

    return false;
  };

  // Save updated row - triggered when stopped editing.
  const processRowUpdate = async (updatedRow, originalRow) => {
    if (!shouldSave(updatedRow, originalRow)) {
      return originalRow;
    }

    const updatedRowData = {
      ...updatedRow,
      coach_id: updatedRow.coach?.id,
      aspect_id: updatedRow.aspect?.id,
      summary_indicator_id: updatedRow.summary_indicator?.id ?? null,
      goal_type_id: updatedRow.goal_type?.id,
    };

    try {
      const response = await updateGoal(updatedRowData, goalsBaseUrl);
      trackCzEvent('CAREEdit:Goal', `User updated goal ${response.id}`, 1);
      setSnackbar({ children: 'Goal saved', severity: 'success' });
      return Promise.resolve(response);
    } catch (error) {
      const errorMessage = Object.entries(error || {})
        .map(([field, messages]) => `${messages[0]}`)
        .join(', ');

      return Promise.reject(new Error(errorMessage || 'An error occurred while saving the Goal'));
    }
  };

  const confirmDelete = (id) => {
    showConfirmDialog({
      title: 'Delete Confirmation',
      description: 'Are you sure you want to delete this Goal?',
      actions: [
        {
          label: 'Cancel',
          variant: 'text',
          color: 'primary',
          onClick: () => {
            hideConfirmDialog();
          },
        },
        {
          label: 'Delete',
          variant: 'contained',
          color: 'primary',
          autoFocus: false,
          onClick: async () => {
            hideConfirmDialog();
            await deleteGoal(id, goalsBaseUrl);
            trackCzEvent('CAREDelete:Goal', `User deleted goal ${id}`, 1);
            setGoalsState((prevGoals) => prevGoals.filter((goal) => goal.id !== id));
          },
        },
      ],
    });
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const [adjustedColumns, handleColumnResize, resetColumnWidths] = usePersistColumnSizes(tableName, columns);

  const handleCloseSnackbar = () => setSnackbar(null);

  const [snackbar, setSnackbar] = useState(null);
  const handleProcessRowUpdateError = useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  return (
    <Box sx={{ marginTop: 5 }} name="goalsTable">
      <Typography variant="h6" className="blue-header" sx={{ mb: 2 }}>
        {title || 'Goals'}
      </Typography>
      <DataGridPro
        apiRef={apiRef}
        rows={sortedGoals}
        columns={adjustedColumns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        onColumnResize={handleColumnResize}
        sx={gridStyles}
        slots={{
          columnMenu: ResetColumnSizesMenu,
        }}
        slotProps={{
          columnMenu: {
            columnProps: {
              onResetColumnSizes: () => resetColumnWidths(),
            },
          },
        }}
        {...(enableActionSteps && {
          getDetailPanelContent: getDetailPanelContent,
          getDetailPanelHeight: getDetailPanelHeight,
        })}
        {...commonConfig({ disableColumnResize: false })}
        {...multiLineConfig}
        initialState={initialState}
      />
      {!!snackbar && (
        <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleCloseSnackbar} autoHideDuration={4000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
}
