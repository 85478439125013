import { useRef, useEffect } from 'react';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, []);
  return ref.current;
}

function useScrollToAnchor(callback) {
  useEffect(() => {
    const aTag = $("a[id='" + location.hash.replace('#', '') + "']");
    if (aTag.offset()) {
      $(document).scrollTop(aTag.offset().top - 75);
    }
    callback && callback();
  }, []);
}

function useDidMountEffect(func, deps = []) {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
}

export { usePrevious, useScrollToAnchor, useDidMountEffect };
