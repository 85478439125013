import React, { useRef, useEffect, useState } from 'react';
import { useEditor } from '@tiptap/react';
import { RichTextEditorProvider, RichTextField, TableBubbleMenu, LinkBubbleMenu } from 'mui-tiptap';
import Controls from './controls';
import { defaultControlsConfig } from './config/default_controls_config';
import useExtensions from './use_extensions';
import { Box } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { transformPastedHTML } from './transform_pasted_html';

/**
 * Rich Text Editor component, based on mui-tiptap library (MUI wrapper for TipTap).
 * See: https://github.com/sjdemartini/mui-tiptap
 *
 * @param {Object} containerRef - A reference to the container element where the editor is rendered.
 * @param {String} initialContent - The initial content of the editor (HTML string or Plain text). In collaboration mode set this via onSynced in the provider.
 * @param {String} placeholder - The placeholder text to display when the editor is empty.
 * @param {String} minHeight - The minimum height of the editor.
 * @param {Boolean} disabled - Whether the editor is disabled or not.
 * @param {Boolean} hideMenuBarIfDisabled - Whether to hide the menu bar when the editor is disabled.
 * @param {Function} onContentChange - A callback function to save the editor content. Automatically debounced for auto-saving.
 * @param {Function} onEditorReady - A callback function to be called when the editor is ready.
 * @param {Array} controlsConfig - An array of control components to render in the editor toolbar.
 * @param isTableResizable
 * @param {Object} collaboration - An object containing the collaboration settings.
 * @returns {Element}
 * @constructor
 */
const Editor = ({
  containerRef,
  initialContent,
  onContentChange = null,
  onEditorReady = null,
  minHeight = '130px',
  disabled = false,
  hideMenuBarIfDisabled = false,
  controlsConfig = defaultControlsConfig(),

  // collaboration settings
  placeholder,
  isTableResizable = true,
  collaboration = { enabled: false, user: {}, provider: null },
}) => {
  const editorRef = useRef(null);
  const initialContentLoaded = useRef(false);
  const [editorContent, setEditorContent] = useState('');

  const extensions = useExtensions({
    placeholder: placeholder || '',
    collaboration: collaboration,
    isTableResizable: isTableResizable,
  });

  const editor = useEditor({
    extensions: extensions,
    editable: !disabled,
    content: '',
    editorProps: {
      transformPastedHTML: transformPastedHTML,
    },
    ref: editorRef,
  });

  useEffect(() => {
    editor?.setOptions({ editable: !disabled });
  }, [disabled]);

  // Only set initial content once
  useEffect(() => {
    if (editor && initialContent && !initialContentLoaded.current) {
      editor.commands.setContent(initialContent);
      initialContentLoaded.current = true;
    }
  }, [editor, initialContent]);

  // TODO: duplicate from above
  // useEffect(() => {
  //   if (editor) {
  //     editor.commands.setContent(initialContent);
  //   }
  // }, [initialContent]);

  const debouncedOnChange = useDebouncedCallback(() => {
    const content = editor?.getHTML();
    if (content !== editorContent) {
      setEditorContent(content);
      if (onContentChange) {
        editor.commands.closeLinkBubbleMenu();
        onContentChange(content);
      }
    }
  }, 700);

  useEffect(() => {
    editor?.on('update', debouncedOnChange);

    if (editor && onEditorReady) {
      onEditorReady(editor);
    }

    // Cleanup effect
    return () => {
      if (editor) {
        // TODO: restore once we fix the autosaving multiple observations issue
        // const finalContent = editor.getHTML();
        // if (onContentChange) {
        //   onContentChange(finalContent);
        // }
        editor.commands.closeLinkBubbleMenu();
        editor.off('update', debouncedOnChange);
        editor.destroy();
      }
    };
  }, [editor]);

  return (
    <>
      <Box
        className="editor-container"
        sx={{
          '& .ProseMirror': {
            minHeight: minHeight,
          },
          // In case we want to limit the height of the editor content..
          // '& .MuiTiptap-RichTextContent-root': {
          //   maxHeight: '160px',
          //   overflowY: 'scroll',
          // },
        }}
      >
        <RichTextEditorProvider editor={editor}>
          <RichTextField
            controls={<Controls controlsConfig={controlsConfig} />}
            variant="outlined"
            MenuBarProps={{
              hide: disabled && hideMenuBarIfDisabled,
            }}
          />
          <LinkBubbleMenu container={containerRef?.current || document.body} />
          <TableBubbleMenu container={containerRef?.current || document.body} />
        </RichTextEditorProvider>
      </Box>
    </>
  );
};

export default Editor;
