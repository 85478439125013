import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { closeButtonSx } from '../../../constants/dialog';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import ActionStepsForm from './action_steps_form';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';

const ActionStepDialog = ({ isOpen, title, onClose, goal, leads, onSave }) => {
  const [description, setDescription] = useState('');
  const [dueDate, setDueDate] = useState(null);
  const [leadId, setLeadId] = useState('');
  const [errors, setErrors] = useState({});

  const formProps = {
    description,
    setDescription,
    dueDate,
    setDueDate,
    leadId,
    setLeadId,
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const payload = {
      description: description,
      due_date: dueDate,
      user_id: leadId,
      goal_id: goal.id,
    };
    const response = await onSave(payload);

    if (!response.errors) {
      setDescription('');
      setDueDate(null);
      setLeadId('');
      setErrors({});
    } else {
      setErrors(response.errors);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog fullWidth open={isOpen} disableEscapeKeyDown={true}>
        <DialogTitle>
          {title}
          <Typography
            variant="body1"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            For Goal: {goal.description}
          </Typography>
        </DialogTitle>
        <IconButton aria-label="close" onClick={onClose} sx={closeButtonSx}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <ActionStepsForm goal={goal} leads={leads} onSave={onSave} formProps={formProps} errors={errors} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default ActionStepDialog;
