import { gridClasses } from '@mui/x-data-grid-pro';

export const gridStyles = {
  [`& .${gridClasses.cell}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
    outline: 'none',
  },
  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
    outline: 'none',
  },
};
