import React from 'react';
import { GridColumnMenu } from '@mui/x-data-grid-pro';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';

function ResetColumnSizesItem({ onResetColumnSizes }) {
  return (
    <MenuItem onClick={onResetColumnSizes}>
      <ListItemIcon>
        <Grid3x3Icon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Reset Table Layout</ListItemText>
    </MenuItem>
  );
}

function ResetColumnSizesMenu({ columnProps, ...props }) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        ResetColumnSizesItem: ResetColumnSizesItem,
      }}
      slotProps={{
        ResetColumnSizesItem: {
          ...columnProps,
        },
      }}
    />
  );
}

export default ResetColumnSizesMenu;
