export const commonConfig = ({ disableColumnResize = true }) => ({
  // sx: {
  //   '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
  //   '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
  //   '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
  // },
  density: 'compact',
  autoHeight: true,
  hideFooter: true,
  disableColumnReorder: true,
  disableAutosize: true,
  disableColumnResize: disableColumnResize,
});
