import { getHeaders } from '../../headers';

export const createObservation = async (observation, baseUrl) => {
  const response = await fetch(`${baseUrl}`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ observation }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }

  return response.json();
};

export const updateObservation = async (id, observation, baseUrl) => {
  const response = await fetch(`${baseUrl}/${id}`, {
    method: 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify({ observation }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw errorData.errors ? errorData.errors : new Error(response.statusText);
  }
  return response.json();
};

export const getObservation = async (id, baseUrl = '/care/observations') => {
  const response = await fetch(`${baseUrl}/${id}`, {
    method: 'GET',
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};

export const deleteObservation = async (id, baseUrl = '/care/observations') => {
  const response = await fetch(`${baseUrl}/${id}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};

export const sendEmails = async (id, recipients, message, baseUrl = '/care/observations') => {
  const response = await fetch(`${baseUrl}/${id}/send_emails`, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ recipients, message }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response.json();
};
