import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { canShowPercentEngaged, getHqimValue, showOtherHqimField } from '../utils';

const MetaTable = ({
  survey,
  observation,
  showObservableAnalysis,
  isPlcMode,
  showHqim,
  hqimTypes,
  showPredominantlyPoc,
  showClassGrouping,
  includesLanguageOfInstruction,
}) => {
  const theme = useTheme();
  const [localObservation] = useState({
    ...observation,
    hqim_type_ids: observation?.hqim_types?.map((t) => t.id),
  });
  const [selectedHqimTypes] = useState(localObservation?.hqim_types?.map((type) => type.id) || []);

  const dateValue = localObservation?.observation_date ? dayjs(localObservation.observation_date) : null;
  const formattedDate = dateValue ? dateValue.format('MM/DD/YYYY') : '';

  const observationOfLabel = () => (isPlcMode ? 'Observation Of' : 'Teacher');

  const groupingsObserved = () => {
    if (localObservation?.whole_group) return 'Whole Group';
    if (localObservation?.small_group) return 'Small Group';
    if (localObservation?.independent) return 'Independent';
    return '';
  };

  const renderPercentEngaged = () => {
    const { number_of_engaged_students, number_of_students } = localObservation || {};
    const percent = (Number(number_of_engaged_students) / Number(number_of_students)) * 100;
    const displayValue = percent % 1 === 0 ? percent : percent.toFixed(2);
    return `${displayValue}%`;
  };

  const renderHqimTypes = () => {
    return hqimTypes
      .filter((type) => selectedHqimTypes.includes(type.id))
      .map((type) => type.name)
      .join(', ');
  };

  const renderRow = (label, value) => (
    <TableRow>
      <TableCell component="th" scope="row" sx={{ width: '40%', backgroundColor: theme.palette.action.hover }}>
        <Typography>{label}</Typography>
      </TableCell>
      <TableCell sx={{ width: '60%' }}>
        <Typography>{value}</Typography>
      </TableCell>
    </TableRow>
  );

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody>
          {renderRow('Status', localObservation?.status === 'completed' ? 'Complete' : 'Incomplete')}
          {renderRow('Observation Date', formattedDate)}
          {renderRow('Observed By', localObservation.observer?.full_name || '')}
          {renderRow('School Name', localObservation?.school_name?.name || '')}
          {!isPlcMode && renderRow('Grade Level', localObservation?.grade_level?.name || '')}
          {renderRow(observationOfLabel(), localObservation?.observable?.name || '')}
          {renderRow('Language of Instruction', localObservation?.instruction_language?.name || '')}
          {!isPlcMode && renderRow('Content', localObservation?.content_area?.name || '')}
          {localObservation.content_area?.show_comments && renderRow('Other Content', localObservation?.other_content || '')}
          {showClassGrouping && renderRow('Groupings Observed', groupingsObserved())}
          {showClassGrouping && renderRow('Number of Students', localObservation?.number_of_students || '')}
          {showClassGrouping && renderRow('Number of Engaged Students', localObservation?.number_of_engaged_students || '')}
          {showClassGrouping && canShowPercentEngaged(localObservation) && renderRow('% Engaged', renderPercentEngaged())}
          {showPredominantlyPoc && renderRow('Classroom with Predominantly Students of Color', localObservation?.diverse_classroom || '')}
          {showHqim && renderRow('HQIM In Use', getHqimValue(localObservation))}
          {showHqim && getHqimValue(localObservation) === 'yes' && renderRow('HQIM Types', renderHqimTypes())}
          {showOtherHqimField(localObservation, hqimTypes) && renderRow('Other HQIM In Use', localObservation?.other_hqim || '')}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MetaTable;
