import Link from '@mui/material/Link';
import React from 'react';

const RubricToggleLink = ({ showRubric, onToggle }) => {
  return (
    <Link
      href="#"
      onClick={(e) => {
        e.preventDefault();
        onToggle(e);
      }}
      variant="body2"
      className="link-default"
    >
      {showRubric ? 'Hide' : 'Show'} Rubric
    </Link>
  );
};

export { RubricToggleLink };
