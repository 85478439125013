export const getFeatureFlagValue = (flagName) => {
  return window.featureFlagClient.variation(flagName, false);
};

export const updateFeatureFlagAfterClientInitialization = (flagName, stateSetter) => {
  return () => {
    setTimeout(() => {
      stateSetter(getFeatureFlagValue(flagName));
    }, 0);

    // in case the client is not ready when the callback above fires
    window.featureFlagClient.on('ready', () => {
      stateSetter(getFeatureFlagValue(flagName));
    });
  };
};
