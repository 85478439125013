export const formatDate = (date, tz = 'UTC') => {
  return new Date(date).toLocaleDateString(undefined, { timeZone: tz });
};

export const formatDateTime = (date, tz = 'UTC') => {
  return new Date(date).toLocaleString({}, { timeZone: tz });
};

export const formatDateTimeFriendly = (date, tz = 'UTC') => {
  const options = {
    timeZone: tz,
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };

  return new Date(date).toLocaleString({}, options);
};

export const formatDateFriendly = (date, format = 'short', tz = 'UTC') => {
  const options = {
    weekday: format === 'long' ? 'long' : 'short',
    year: 'numeric',
    month: format === 'long' ? 'long' : 'short',
    day: 'numeric',
    timeZone: tz,
  };
  return new Date(date).toLocaleDateString({}, options);
};

export const formatTimeFriendly = (time, tz = 'UTC') => {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: tz,
  };
  return new Date(time).toLocaleTimeString({}, options);
};
