export const canShowPercentEngaged = (observation) => {
  const { number_of_engaged_students, number_of_students } = observation || {};

  const isValidValue = (value) => {
    if (value === undefined || value === '') return false;
    if (typeof value !== 'string' && typeof value !== 'number') return false;
    const numberValue = Number(value);
    return !isNaN(numberValue) && numberValue >= 0;
  };

  if (!isValidValue(number_of_engaged_students) || !isValidValue(number_of_students)) {
    return false;
  }

  const total = Number(number_of_students);
  const engaged = Number(number_of_engaged_students);

  return engaged >= 0 && total >= 0 && engaged <= total;
};

export const getHqimValue = (observation) => {
  if (!observation || observation.hqim_in_use === undefined || observation.hqim_in_use === null) {
    return '';
  } else {
    return observation.hqim_in_use ? 'yes' : 'no';
  }
};

export const showOtherHqimField = (observation, hqimTypes) => {
  return observation.hqim_type_ids?.includes(hqimTypes.filter((t) => t.show_comments)[0]?.id);
};
