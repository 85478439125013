import { useState } from 'react';
import { alertError } from '../components/shared/alert_error';
import { errorMessage } from './rails_errors';
import { useDidMountEffect } from './hooks';
import { pick } from 'lodash';

// edit/editing states, handles cancel/edit and processing api request with fallback
export function useFormStatus({ props, fields = [] } = {}) {
  const item = pick(props, [...fields, 'lock_version']);
  const [loading, setLoading] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [errors, setErrors] = useState({});
  const [edited, setEdited] = useState(false);
  const [formData, setFormData] = useState(item);
  const [initialData, setInitialData] = useState(item);

  useDidMountEffect(() => setInitialData(item), Object.values(item));

  const clearErrors = () => {
    setErrors({});
  };

  const handleCancel = () => {
    setEditing(false);
    setEdited(false);
    setFormData({ ...formData, ...initialData });
  };

  const handleChange = (field, e) => {
    setEdited(true);
    setFormData({ ...formData, [`${field}`]: e.target.value });
  };

  const handleEdit = () => setEditing(true);

  const processWithErrorFallback = (fnPromise, options = { withAlert: false, withMessageError: false, withErrorThrow: false }) => {
    const { withAlert, withMessageError, withErrorThrow } = options;
    setLoading(true);
    return fnPromise
      .then(() => {
        setEditing(false);
        setEdited(false);
        setErrors({});
        if (withAlert) alertError({});
      })
      .catch((error) => {
        if (!withAlert && !withMessageError) setErrors(error.response?.data?.errors || {});
        if (withMessageError) {
          setErrors({ base: errorMessage(error) });
        } else if (withAlert || !error.response?.data?.errors) {
          alertError({ error });
        }
        if (withErrorThrow) throw error;
      })
      .finally(() => setLoading(false));
  };

  return {
    processWithErrorFallback,
    formData,
    isEditing,
    setEditing,
    edited,
    setEdited,
    errors,
    loading,
    handleCancel,
    handleChange,
    handleEdit,
    clearErrors,
  };
}
