import React from 'react';
import PropTypes from 'prop-types';

const AdmDescription = ({ description, style }) => {
  if (!description) return null;
  const defaultStyle = {
    fontSize: 15,
    color: '#666',
  };
  const mergedStyle = { ...defaultStyle, ...style };
  return <div style={mergedStyle}>{description}</div>;
};

AdmDescription.propTypes = {
  description: PropTypes.string,
  style: PropTypes.object,
};

export default AdmDescription;
