import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleInfo, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { useTheme } from '@mui/material/styles';

const iconMap = {
  success: faCircleCheck,
  info: faCircleInfo,
  warning: faTriangleExclamation,
};

const ConfirmDialog = ({ isOpen, title, description, onClose, actions, fullWidth, maxWidth, ...preset }) => {
  const theme = useTheme();
  const selectedIcon = iconMap[preset.icon] || faTriangleExclamation;
  const iconColor = theme.palette[preset.icon]?.main || theme.palette.warning.main;

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">
        <Stack direction="row" spacing={1} alignItems="start">
          <FontAwesomeIcon icon={selectedIcon} style={{ color: iconColor, fontSize: '1.5rem', paddingTop: '3px' }} />
          <Stack direction="column">
            <Typography variant="h6" fontWeight="bolder">
              {title}
            </Typography>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {actions.map((action, index) => (
          <Button key={index} onClick={() => action.onClick(onClose)} variant={action.variant} color={action.color} autoFocus={action.autoFocus}>
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  isOpen: false,
  title: 'Confirmation Required',
  description: 'Are you sure you want to proceed with this action?',
  onClose: () => {},
  actions: [
    { label: 'Cancel', onClick: () => {}, variant: 'text', color: 'primary' },
    {
      label: 'Save',
      onClick: () => {},
      variant: 'contained',
      color: 'primary',
      autoFocus: true,
    },
  ],
  fullWidth: true,
  maxWidth: 'sm',
};

export default ConfirmDialog;
