export default (url, options) => {
  const getCsrfToken = () => {
    if (typeof document !== 'undefined') {
      return document.querySelector("[name='csrf-token']")?.content || 'test';
    }
    return 'test';
  };
  return fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      'X-CSRF-TOKEN': getCsrfToken(),
    },
  });
};
