const FONT_SIZE_REGEX = /font-size:\s*([^;"]+);?/gi;
const FONT_FAMILY_REGEX = /font-family:\s*([^;"]+);?/gi;

export function transformPastedHTML(html) {
  return html.replace(/<[^>]+style="([^"]*)"/gi, (match, styleContent) => {
    let cleanedStyle = styleContent
      .replace(FONT_FAMILY_REGEX, '') // Remove 'font-family'
      .replace(FONT_SIZE_REGEX, ''); // Remove 'font-size'

    cleanedStyle = cleanedStyle.trim();
    if (cleanedStyle) {
      return match.replace(`style="${styleContent}"`, `style="${cleanedStyle}"`);
    } else {
      return match.replace(` style="${styleContent}"`, ''); // Remove the entire style attribute if empty
    }
  });
}
