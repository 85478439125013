import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Resources from '../../shared/resources';
import UploadAction from '../../actions/upload';
import { Stack } from '@mui/material';

const Attachments = ({ actionStep }) => {
  const [attachments, setAttachments] = useState(actionStep?.attachments || []);
  const isDisabled = !actionStep?.id;

  const handleUpload = (attachment) => {
    setAttachments((prevAttachments) => [...prevAttachments, attachment]);
  };

  const handleRemove = (attachments) => {
    setAttachments(attachments);
  };

  return (
    <Stack direction="row" alignItems="center">
      <Resources resources={attachments} handleUpdate={handleRemove} />
      <UploadAction url={`/care/action_steps/${actionStep.id}/attach_file`} handleUpload={handleUpload} className="ms-2" isDisabled={isDisabled} />
    </Stack>
  );
};

Attachments.propTypes = {
  actionStep: PropTypes.object,
};

export default Attachments;
