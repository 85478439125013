import dayjs from 'dayjs';

import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { DataGridPro, GridActionsCellItem, GridRowModes } from '@mui/x-data-grid-pro';
import { Typography, Box, Checkbox, Snackbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faPencil, faTrashCan, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { commonConfig } from '../../../helpers/data_grid/common_config';
import { multiLineConfig } from '../../../helpers/data_grid/multi_line_config';
import usePersistColumnSizes from '../../../helpers/data_grid/use_persisted_column_sizes';
import ResetColumnSizesMenu from '../../../helpers/data_grid/reset_column_sizes_menu';
import { useConfirmDialog } from '../../modals/confirm_dialog';
import { gridStyles } from '../../../helpers/data_grid/common_grid_styles';
import Alert from '@mui/material/Alert';
import Attachments from './attachments';

export default function ActionStepsTable({ title = 'Action Steps', goal, leads, onUpdate, onDelete, canEdit = false }) {
  const theme = useTheme();
  const { showConfirmDialog, hideConfirmDialog } = useConfirmDialog();
  const [actionSteps, setActionSteps] = useState(goal.action_steps || []);
  const [rowModesModel, setRowModesModel] = useState({});

  const leadOptions = useMemo(() => leads?.map((lead) => ({ id: lead.id, full_name: lead.full_name })), [leads]);

  useEffect(() => {
    setActionSteps(goal.action_steps);
  }, [goal.action_steps]);

  const sortedActionSteps = useMemo(() => {
    return [...actionSteps].sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
  }, [actionSteps]);

  const toggleComplete = useCallback(
    async (event, actionStep) => {
      event.preventDefault();

      // optimistic update for immediate UI feedback
      const updatedActionStep = { ...actionStep, closed: !actionStep.closed };
      setActionSteps((prevSteps) => prevSteps.map((step) => (step.id === actionStep.id ? updatedActionStep : step)));

      try {
        await onUpdate(goal.id, updatedActionStep);
      } catch (error) {
        console.error('error', error);
        // revert back to original state if update fails
        setActionSteps((prevSteps) => prevSteps.map((step) => (step.id === actionStep.id ? actionStep : step)));
      }
    },
    [onUpdate, goal.id]
  );

  const confirmDelete = useCallback(
    (id) => {
      showConfirmDialog({
        title: 'Delete Confirmation',
        description: 'Are you sure you want to delete this Action Step?',
        actions: [
          {
            label: 'Cancel',
            variant: 'text',
            color: 'primary',
            onClick: () => {
              hideConfirmDialog();
            },
          },
          {
            label: 'Delete',
            variant: 'contained',
            color: 'primary',
            autoFocus: false,
            onClick: async () => {
              hideConfirmDialog();
              // optimistic update for immediate UI feedback
              setActionSteps((prevSteps) => prevSteps.filter((step) => step.id !== id));
              await onDelete(id, goal.id);
            },
          },
        ],
      });
    },
    [onDelete, goal.id]
  );

  const handleEditClick = useCallback(
    (id) => () => {
      const newModel = { ...rowModesModel, [id]: { mode: GridRowModes.Edit } };
      setRowModesModel(newModel);
    },
    []
  );

  const handleSaveClick = useCallback(
    (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    []
  );

  const handleCancelClick = useCallback(
    (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    },
    []
  );

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const shouldSave = (updatedRow, originalRow) => {
    if (updatedRow.description !== originalRow.description) {
      return true;
    }
    if (updatedRow.due_date !== originalRow.due_date) {
      return true;
    }
    if (updatedRow.user?.id !== originalRow.user?.id) {
      return true;
    }
    return false;
  };

  const [snackbar, setSnackbar] = useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const handleProcessRowUpdateError = useCallback((error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const processRowUpdate = async (updatedRow, originalRow) => {
    if (!shouldSave(updatedRow, originalRow)) {
      return Promise.resolve(originalRow);
    }

    const updatedActionStep = { ...originalRow, ...updatedRow };
    updatedActionStep.user_id = updatedActionStep.user?.id;

    try {
      await onUpdate(goal.id, updatedActionStep);
      setSnackbar({ children: 'Action Step saved', severity: 'success' });
      return Promise.resolve(updatedRow);
    } catch (error) {
      const errorMessage = Object.entries(error || {})
        .map(([field, messages]) => `${messages[0]}`)
        .join(', ');

      return Promise.reject(new Error(errorMessage || 'An error occurred while saving the Action Step'));
    }
  };

  const columns = useMemo(
    () => [
      {
        field: 'description',
        type: 'string',
        headerName: 'Action Step',
        editable: canEdit,
        flex: 2,
        minWidth: 100,
        valueFormatter: (value) => value || '',
      },
      {
        field: 'due_date',
        type: 'date',
        headerName: 'Due Date',
        editable: canEdit,
        valueFormatter: (value) => {
          return value ? dayjs(value).format('MM/DD/YYYY') : null;
        },
        flex: 0.7,
        valueGetter: (value, row) => {
          return row.due_date || null;
        },
        valueSetter: (value, row) => {
          const date = dayjs(value).format('YYYY-MM-DD');
          return { ...row, due_date: date };
        },
      },
      {
        field: 'closed',
        type: 'boolean',
        headerName: 'Complete',
        editable: canEdit,
        flex: 0.7,
        renderCell: (params) => {
          return <Checkbox checked={params.value} disabled={!canEdit} onClick={(e) => toggleComplete(e, params.row)} />;
        },
      },
      {
        field: 'user',
        type: 'singleSelect',
        headerName: 'Lead',
        editable: canEdit,
        valueOptions: leadOptions,
        valueFormatter: (value, row) => (row.user ? row.user.full_name : ''),
        getOptionValue: (option) => option.id,
        getOptionLabel: (option) => option.full_name,
        valueGetter: (value, row) => value.id,
        valueSetter: (value, row) => ({ ...row, user: leadOptions.find((option) => option.id === value) }),
        flex: 0.7,
      },
      {
        field: 'attachments',
        headerName: 'Resources',
        flex: 1,
        editable: false,
        valueFormatter: (value) => value || '',
        renderCell: (params) => <Attachments actionStep={params.row} />,
      },

      ...(canEdit
        ? [
            {
              field: 'actions',
              type: 'actions',
              headerName: 'Actions',
              width: 75,
              getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                return isInEditMode
                  ? [
                      <GridActionsCellItem
                        key={`save-${id}`}
                        icon={<FontAwesomeIcon icon={faFloppyDisk} color={theme.palette.success[600]} style={{ fontSize: '1.1rem' }} />}
                        label="Save"
                        sx={{
                          color: 'primary.main',
                        }}
                        onClick={handleSaveClick(id)}
                      />,
                      <GridActionsCellItem
                        key={`cancel-${id}`}
                        icon={<FontAwesomeIcon icon={faXmark} color={theme.palette.grey[600]} style={{ fontSize: '1.1rem' }} />}
                        label="Cancel"
                        onClick={handleCancelClick(id)}
                      />,
                    ]
                  : [
                      <GridActionsCellItem
                        key={`edit-${id}`}
                        icon={<FontAwesomeIcon icon={faPencil} color={theme.palette.grey[500]} style={{ fontSize: '1.1rem' }} />}
                        label="Edit"
                        onClick={handleEditClick(id)}
                      />,
                      <GridActionsCellItem
                        key={`delete-${id}`}
                        icon={<FontAwesomeIcon icon={faTrashCan} color={theme.palette.grey[500]} style={{ fontSize: '1.1rem' }} />}
                        label="Delete"
                        onClick={(e) => confirmDelete(id)}
                      />,
                    ];
              },
            },
          ]
        : []),
    ],
    [canEdit, toggleComplete, confirmDelete, rowModesModel]
  );

  const [adjustedColumns, handleColumnResize, resetColumnWidths] = usePersistColumnSizes('observableDashActionSteps', columns);

  return (
    <Box sx={{ marginTop: 0 }}>
      <Typography variant="body1" sx={{ mb: 1 }}>
        {title || 'Action Steps'}
      </Typography>
      <DataGridPro
        rows={sortedActionSteps}
        columns={adjustedColumns}
        onColumnResize={handleColumnResize}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        sx={gridStyles}
        slots={{
          columnMenu: ResetColumnSizesMenu,
        }}
        slotProps={{
          columnMenu: {
            columnProps: {
              onResetColumnSizes: () => resetColumnWidths(),
            },
          },
        }}
        {...commonConfig({ disableColumnResize: false })}
        {...multiLineConfig}
      />
      {!!snackbar && (
        <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} onClose={handleCloseSnackbar} autoHideDuration={4000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
}
