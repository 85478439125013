import * as React from 'react';
import { Stack, Link, Typography } from '@mui/material';
import logo from 'images/whiteLogo.png';

const linkStyles = {
  color: 'white',
  textDecoration: 'none',
};

const spacerStyles = {
  color: '#67bbb9',
};

const Footer = (props) => {
  return (
    <div
      style={{
        flexShrink: 0,
        width: '100%',
        height: 87,
        background: '#707070 0% 0% no-repeat padding-box',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '30px 80px',
      }}
    >
      <Stack direction="row" spacing={2}>
        <img src={logo} />
        <Link href="https://attunedpartners.com/terms-of-use/" sx={linkStyles}>
          Terms of Use
        </Link>
        <Typography sx={spacerStyles}>|</Typography>
        <Link href="https://attunedpartners.com/privacy-policy-2" sx={linkStyles}>
          Privacy Policy
        </Link>
        <Typography sx={spacerStyles}>|</Typography>
        <Link href="/dashboard" sx={linkStyles}>
          About
        </Link>
        <Typography sx={spacerStyles}>|</Typography>
        <Link href="/dashboard" sx={linkStyles}>
          Support
        </Link>
      </Stack>
      <Typography>© 2023 Attuned Education Partners</Typography>
    </div>
  );
};

export default Footer;
