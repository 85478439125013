export const defaultControlsConfig = () => [
  'MenuButtonToggleTitle',
  'MenuButtonBold',
  'MenuButtonItalic',
  'MenuButtonStrikethrough',
  'MenuButtonEditLink',
  'MenuButtonOrderedList',
  'MenuButtonBulletedList',
  'MenuButtonTaskList',
  'MenuDivider',
  'MenuButtonRemoveFormatting',
  'MenuButtonUndo',
  'MenuButtonRedo',
];
