import React, { useState } from 'react';
import { RubricToggleLink } from './rubric_toggle_link';
import { RubricContent } from './rubric_content';

const RubricToggle = ({ rubricHtml }) => {
  const [showRubric, setShowRubric] = useState(false);

  const toggleRubric = (e) => {
    e.preventDefault();
    setShowRubric(!showRubric);
  };

  return (
    <div>
      <RubricToggleLink showRubric={showRubric} onToggle={(e) => toggleRubric(e)} />
      {showRubric && <RubricContent rubricHtml={rubricHtml} show={showRubric} />}
    </div>
  );
};

export default RubricToggle;
