import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';

const StyledTooltip = ({ title }) => {
  return (
    <Tooltip
      arrow
      title={title}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: '#346189',
            padding: '10px',
            fontWeight: 400,
            fontSize: 14,
            width: 500,
            opacity: 1,
            lineHeight: 1.1,
            '& .MuiTooltip-arrow': {
              color: '#346189',
              opacity: 1,
            },
          },
        },
      }}
    >
      <FontAwesomeIcon icon={faCircleInfo} className="ms-2" style={{ fontSize: 16 }} />
    </Tooltip>
  );
};

const AdmTooltip = ({ config }) => {
  if (!config || !config.tooltip_text) return null;
  return <StyledTooltip title={config.tooltip_text} />;
};

export default AdmTooltip;
