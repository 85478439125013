export const errorMessage = (error) => {
  const data = error.response?.data;
  let message = data?.errors || data?.error || data?.message || error.response?.statusText;
  if (message && typeof message === 'object') {
    message = Object.entries(message)
      .map(([k, v]) => {
        const reason = Array.isArray(v) ? v.join(' ') : v;
        return `${k}: ${reason}`;
      })
      .join('\n');
  }
  return data ? message : `${error.status} ${message}`;
};

export const textError = (errors) => {
  if (!errors) return null;
  return Array.isArray(errors) ? errors.join(' ') : errors;
};
