import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Resources from '../../shared/resources';
import UploadAction from '../../actions/upload';
import { Stack } from '@mui/material';

const Attachments = ({ ...props }) => {
  const [attachments, setAttachments] = useState(props?.observation?.attachments || []);
  const hideTitle = props.hideTitle || false;
  const hideUpload = props.hideUpload || false;

  const handleUpload = (attachment) => {
    setAttachments((prevAttachments) => [...prevAttachments, attachment]);
  };

  const handleRemove = (attachments) => {
    setAttachments(attachments);
  };

  const isDisabled = !props.observation?.id;

  return (
    <Stack direction="column">
      {!hideTitle && <span className="me-2">Resources</span>}
      <Stack direction="row" alignItems="center">
        <Resources resources={attachments} handleUpdate={handleRemove} />
        {!hideUpload && (
          <UploadAction
            url={`/care/observations/${props.observation.id}/attach_file`}
            handleUpload={handleUpload}
            className="ms-2"
            isDisabled={isDisabled}
          />
        )}
      </Stack>
    </Stack>
  );
};

Attachments.propTypes = {
  observation: PropTypes.object,
};

export default Attachments;
