import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => (option.name ? option.name.toLowerCase() : ''),
});

const AutocompleteWithInsert = (props) => {
  const [value, setValue] = React.useState(props.initialValue || null);
  const [open, toggleOpen] = React.useState(false);
  const [dialogValue, setDialogValue] = React.useState({ name: '', id: '' });
  const sx = props.sx || {};
  const { allowNewItems = true } = props;

  useEffect(() => {
    const matchedOption = value && props.options.find((option) => option.name === value.name);
    if (matchedOption && matchedOption.id !== value.id) {
      setValue(matchedOption);
    }
  }, [props.options, value]);

  const handleClose = () => {
    setDialogValue({
      name: '',
      id: '',
    });
    toggleOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newItem = {
      name: dialogValue.name,
      id: dialogValue.id,
    };
    props.onChange && props.onChange(newItem);
    setValue(newItem);
    handleClose();
  };

  const handleSelection = (event, newValue) => {
    event.preventDefault();
    props.onChange && props.onChange(newValue);
    setValue(newValue);
  };

  const handleRemove = () => {
    props.onRemove &&
      props.onRemove(value, (shouldRemove) => {
        if (shouldRemove) {
          setValue(null);
        }
      });
  };

  const hasErrors = () => props.errors && props.errors.length > 0;

  const errorMessage = () => (hasErrors() ? props.errors[0] : '');

  return (
    <React.Fragment>
      <Autocomplete
        disabled={props.readOnly}
        id={props.inputId}
        onChange={(event, newValue) => {
          if (newValue === null) {
            handleRemove();
          } else if (allowNewItems && newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              name: newValue.inputValue,
              id: '',
            });
          } else {
            handleSelection(event, newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filterOptions(options, params);
          if (allowNewItems && params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name || option.inputValue || option}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        renderInput={(params) => (
          <TextField
            disabled={props.readOnly}
            {...params}
            required={props.required || false}
            error={hasErrors()}
            // helperText={errorMessage()}
            label={`${props.label || 'Select or Insert'}`}
            size="small"
            sx={sx}
          />
        )}
        options={props.options}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        required={props.required || false}
        data-cy={props.label}
        value={value}
      />
      {allowNewItems && (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
          <form onSubmit={handleSubmit}>
            <DialogTitle>{props.dialogTitle || 'Add a new item'}</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                fullWidth
                margin="dense"
                id="name"
                value={dialogValue.name}
                onChange={(event) =>
                  setDialogValue({
                    ...dialogValue,
                    name: event.target.value,
                  })
                }
                label={props.nameLabel || 'Name'}
                type="text"
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit" color="primary" variant="contained">
                Add
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </React.Fragment>
  );
};

AutocompleteWithInsert.propTypes = {
  options: PropTypes.array.isRequired,
  initialValue: PropTypes.object,
  label: PropTypes.string,
  dialogTitle: PropTypes.string,
  dialogContentText: PropTypes.string,
  nameLabel: PropTypes.string,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  allowNewItems: PropTypes.bool,
};

export default AutocompleteWithInsert;
